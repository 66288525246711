import * as React from "react";
import { RouterProvider } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import { router } from "./helpers/router";
import Loader from "../common/Loader";
import { ThemeProvider } from "@mui/material";
import { useMuiTheme } from "src/components/themes/workspace";

const App = () => {
  const theme = useMuiTheme();

  return (
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <RouterProvider
            router={router}
            future={{ v7_startTransition: true }}
            fallbackElement={<Loader />}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
};

export default App;
