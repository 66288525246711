import * as React from 'react';
import {Box, createTheme} from "@mui/material";
import {ReactComponent as ArrowDownIcon} from "../common/icons/ArrowDown.svg";
import {ReactComponent as CloseIcon} from "../common/icons/Close.svg";
import Loader from "../common/Loader";
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { mapLangToMuiLocale, useLanguage } from "src/i18n";

declare module "@mui/material/Button" {
    interface TextFieldPropsVariantOverrides {
        dashed: true;
    }
}

export const workspaceRootTheme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: "Roboto, sans-serif",
                    fontSize: 14,
                },
            },
        },
        MuiChip: {
            defaultProps: {
                deleteIcon: <CloseIcon stroke="var(--usedesk-shades-black-80)" strokeWidth="1" width="9" height="9"/>,
            },
            styleOverrides: {
                root: {
                    backgroundColor: "var(--usedesk-shades-black-10)",
                    color: "var(--usedesk-shades-black-80)",
                    borderRadius: '6px',
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "calc(50% - 8px)",
                    fontFamily: "Roboto, sans-serif",
                    fontSize: '14px',
                    lineHeight: '18px',
                    height: '28px',
                    padding: '5px 16px',
                    "& .MuiChip-label": {
                        fontFamily: "Roboto, sans-serif",
                        padding: 0,
                        marginRight: '8px'
                    },
                    "& .MuiChip-root": {
                        backgroundColor: "var(--gray-main)",
                        color: "var(--tickets-table-text-color)",
                    },
                    "& .MuiChip-deleteIcon": {
                        padding: "3px",
                        height: "14px",
                        width: "14px",
                        margin: 0,
                        flex: '0 0 auto',
                    },
                },
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                popupIcon: <ArrowDownIcon width="20" height="20"/>,
                freeSolo: false,
                loadingText: <Loader size={30}/>,
                renderOption: (props, option, state, ownerState) => (
                    <Box component="li" className={autocompleteClasses.option} title={ownerState.getOptionLabel(option)} {...props}>{ownerState.getOptionLabel(option)}</Box>
                )
            },
            styleOverrides: {
                listbox: {
                    "& .MuiAutocomplete-option": {
                        fontFamily: "Roboto, sans-serif",
                        padding: "6px 12px",
                        fontSize: "14px",
                        color: "var(--usedesk-shades-black-80)",
                        display: "block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: 'nowrap',
                    },
                },
                root: {
                    "& .MuiInputBase-root": {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    "& .MuiAutocomplete-noOptions": {
                        fontFamily: "Roboto, sans-serif",
                        fontSize: '14px',
                        padding: '6px 12px',
                        color: 'var(--usedesk-shades-black-80)',
                    },
                    "&.MuiAutocomplete-paper": {
                        outline: "1px solid #fff",
                        marginTop: "2px",
                        marginBottom: "2px",
                        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 10px 16px',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiInputBase-input": {
                        padding: "8px 12px",
                    },
                    input: {
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "16px",
                    },
                    "& .MuiInputBase-root": {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "var(--tickets-table-text-color)",
                    fontSize: "14px",
                }
            }
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    overflow: "auto",
                }
            }
        }
    },
});

export const useMuiTheme = () => {
    const language = useLanguage();
    return React.useMemo(
      () => createTheme(workspaceRootTheme, mapLangToMuiLocale[language]),
      [language],
    );
};
